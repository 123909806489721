import { makeRequest } from '~/lib/api/apiHelpers'
import { Trip } from '~/types/api'

export interface GetTripsResponse {
    trips: Trip[]
}

export async function getTrips({
    signal,
}: {
    signal: AbortSignal
}): Promise<GetTripsResponse> {
    return await makeRequest({ path: '/trips', method: 'GET', signal })
}

export function getTripsQueryKey() {
    return ['trips']
}
